import React from 'react';
import './index.css';
import {Button, Divider, Form, Input, message} from 'antd';
import {Post} from '../../Http';
export default class index extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            'button_loading':false
        };
    }
    render() {
        return (
            <div className = "login" >
            <div className = "login-centent" >
            <Divider orientation="left">登录 </Divider>
            <UserFrom refs={this} />
            </div>
            </div>
        );
    }
}

function UserFrom(pro)
{
    let refs = pro.refs;
    const validateMessages = {
            required: "不能为空",
            types:{
                email:'邮箱格式错误'
            }
        };
        const onFinish = (value:any) => {
            refs.setState({
                'button_loading':true
            });
            Post('Login/verify',value).then(res => {
                refs.setState({
                    'button_loading':false
                });
                if(res.code === 200)
                {
                    message.success('登录成功');
                    sessionStorage.setItem('UserInfo',JSON.stringify(res.data));
                        refs.props.refs.setState({
                            'islogin':true
                        });
                        refs.props.refs.communication('readLogin');
                    return;
                }
                message.error(res.data);
            });
        };
    return (
        <div className="login-form">
        <Form
            validateMessages={ validateMessages }
            name="login"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            autoComplete="off"
            onFinish={onFinish}
        >
        <Form.Item
            label="账号"
            name="username"
            rules={[{ required: true,type:'email'}]}
        >
            <Input maxLength="18" />
        </Form.Item>

        <Form.Item
            label="密码"
            name="password"
            rules={[{ required: true,min:8 }]}
        >
            <Input.Password  maxLength="18" />
        </Form.Item>

        <Button type="primary"
                loading={refs.state.button_loading}
                className="login-button" htmlType="submit">
            登录
        </Button>
    </Form>
    </div>
    );
}
