import React from 'react';

import {Button, Divider, Form, Input,Popconfirm,Modal, message,Space,Table,Pagination} from 'antd';
import {Post} from '../../../Http';
import './index.css';
import { SearchOutlined } from '@ant-design/icons';
const { TextArea } = Input;
export default class index extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            /*驳回审核*/
            'turnDownStatus':false,
            'turnDownId':'',
            'turnDownLoading':false,
            'turnDownType':'',


            /*显示类型*/
            'ShowType':0,

            /*列表加载中*/
            'listLoading':false,
            /*数据总数*/
            'page':1,
            'limit':5,
            'listCount':0,
            'dataSource':[],
            'columns':[
                { title: '用户ID', dataIndex: 'wuserid', key: 'wuserid',width:90},
                { title: '名称',dataIndex: 'wname',key: 'wname',width:100},
                { title: 'OPENID',dataIndex: 'waccount',key: 'waccount',width:140 },
                { title: '金额',dataIndex: 'wmonery',key: 'wmonery',width:70 },
                { title: '申请时间',dataIndex: 'wtime',key: 'wtime',width:120 },
                { title: '处理时间',dataIndex: 'wendtime',key: 'wendtime',width:120 },
                { title: '驳回原因',dataIndex: 'wtext',key: 'wtext',width:160 },
                { title: '操作',dataIndex: 'wid', key: 'wid',width:150,
                    render: (id,item) => (
                        <StatusButtom refs={[this,item]} />
                    ),
                }
            ]
        };
    }
    /*初始化*/
    componentDidMount()
    {
        this.pageChange();

    }

    pageChange = (page = 1,limit = 5) =>
    {
        let rhis = this;
        this.setState({
            'listLoading':true,
            'page':page,
            'limit':limit,
        },() => {
            Post('User/withdraw',{
                'mode':'list',
                'page':rhis.state.page,
                'type':rhis.state.ShowType,
                'limit':rhis.state.limit
            }).then(res => {
                if(res.code === 200)
                {
                    rhis.setState({
                        'page':page,
                        'limit':limit,
                        'listLoading':false,
                        'listCount':res.data.count,
                        'dataSource':res.data.list
                    });
                    return;
                }
                message.error(res.data);
            });
        });
    }
    /*搜索用户*/
    SearchOrder = (id) => {
        if(id !== '')
        {

        }
    }
    /*审核操作*/
    ConfirmOrder = (id,type) => {
        let rhis = this;
        Post('User/withdraw',{
            'id':id,
            'mode':'update',
            'type':type,
            'text':''
        }).then(res => {
            if(res.code === 200)
            {
                rhis.pageChange();
                message.success(res.data);
                return;
            }
            message.error(res.data);
        });
    }
    /*驳回审核*/
    CancelOrder = (id,type) => {
        this.setState({
            'turnDownStatus':true,
            'turnDownId':id,
            'turnDownType':type
        });
    }
    /*切换类型*/
    SwitchOrderType = (type) => {
        this.setState({'ShowType':type,'page':1 },() => {
            this.pageChange();
        });
    }
    render()
    {
        return (
                <div>
                <Divider orientation="left" >
                    <Space>
                        <Button onClick={() => { this.SwitchOrderType(0) }} type={this.state.ShowType === 0 ? "primary":''}>待处理</Button>
                        <Button onClick={() => { this.SwitchOrderType(1) }} type={this.state.ShowType === 1 ? "primary":''}>已处理</Button>
                        <Button onClick={() => {this.SearchOrder('')}}
                                icon={<SearchOutlined />}
                                className="search-order"
                        >搜索</Button>
                    </Space>
                </Divider>
                <Table
                        loading={this.state.listLoading}
                        dataSource={this.state.dataSource}
                        pagination={false}
                        columns={this.state.columns} />
                <Pagination
                    className="manage"
                    onChange={this.pageChange}
                    total={this.state.listCount}
                    pageSize={this.state.limit}
                    current={this.state.page}
                    showSizeChanger={true}
                    pageSizeOptions={['5','10','20']}
                     />


                    <CloseOrder refs={this} />
        </div>
        );
    }
}
/*驳回审核*/
function CloseOrder(pro)
{
    let refs = pro.refs;
    const [form] = Form.useForm();

    const handleCancel = () => {
        refs.setState({
           'turnDownStatus':false
        });
    };
    const onFinish = (values: any) => {
        refs.setState({
            'turnDownLoading':true
        });
        Post('User/withdraw',{
            'id':refs.state.turnDownId,
            'mode':'update',
            'type':2,
            'text':values.reason
        }).then(res => {
            if(res.code === 200)
            {
                refs.pageChange();
                refs.setState({
                    'turnDownStatus':false,
                    'turnDownLoading':false
                });
                message.success(res.data);
                return;
            }
            message.error(res.data);
        });
    };
    return (
        <Modal title={'驳回原因'} width={400}
                destroyOnClose={true}
                footer={null}
                visible={refs.state.turnDownStatus} onCancel={handleCancel} >
    <Form
    form={form}
    layout='horizontal'
    name="chuku"
    labelCol={{ span: 0 }}
    wrapperCol={{ span: 25 }}
    onFinish={onFinish}
    autoComplete="off" >
                <Form.Item
                className="ant-form-item-buttom"
                key={'kk'}
                name="reason"
                rules={[{ required: true, message: '请填写原因' }]}>
                    <TextArea  rows={5} />
                </Form.Item>

            <Form.Item className="ant-form-item-buttom" wrapperCol={{ offset:0, span: 0 }}>
            <Button type="primary"
                className="close-order-bottom"
                loading={refs.state.turnDownLoading}
                htmlType="submit">确认</Button>
            </Form.Item>
        </Form>
        </Modal>
    );
}
/*操作按钮*/
function StatusButtom(pro)
{
    let refs = pro.refs;
    let buttom = '';
    switch(refs[1].wstatic) {
        case 0:
            buttom = (
                <Space>
                    <Popconfirm
                        title="确认已打款吗？"
                        okText="确认"
                        cancelText="取消"
                        onConfirm={() => {refs[0].ConfirmOrder(refs[1].wid,1) }}>
                        <Button type="primary" shape="round" size="small">同意</Button>
                    </Popconfirm>
                    <Button onClick={() => {refs[0].CancelOrder(refs[1].wid,2) }} type="primary" shape="round" size="small" danger>驳回</Button>
                    </Space>);
            break;
        default:
            buttom =  (<Space> </Space>);
    }

    return buttom;
}