import React from 'react';

import {Button, Divider, message,Space,Table,Pagination} from 'antd';
import {Post} from '../../../Http';
import './index.css';
import { SearchOutlined } from '@ant-design/icons';
export default class index extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            /*列表加载中*/
            'listLoading':false,
            /*数据总数*/
            'page':1,
            'limit':5,
            'listCount':0,
            'dataSource':[],
            'columns':[
                { title: 'ID', dataIndex: 'uid', key: 'uid',width:90},
                { title: '昵称',dataIndex: 'uccount',key: 'uccount',width:100},
                { title: '余额',dataIndex: 'uinteg',key: 'uinteg',width:120 },
                { title: '登录时间',dataIndex: 'unewtime',key: 'unewtime',width:150 },
                { title: '会员到期天数',dataIndex: 'tvipendtime',key: 'tvipendtime',width:150 }
            ]
        };
    }
    /*初始化*/
    componentDidMount()
    {
        this.pageChange();

    }

    pageChange = (page = 1,limit = 5) =>
    {
        let rhis = this;
        this.setState({
            'listLoading':true,
            'page':page,
            'limit':limit,
        },() => {
            Post('User/userList',{
                'mode':'list',
                'page':rhis.state.page,
                'limit':rhis.state.limit
            }).then(res => {
                if(res.code === 200)
                {
                    rhis.setState({
                        'page':page,
                        'limit':limit,
                        'listLoading':false,
                        'listCount':res.data.count,
                        'dataSource':res.data.list
                    });
                    return;
                }
                message.error(res.data);
            });
        });
    }
    /*搜索用户*/
    SearchOrder = (id) => {
        if(id !== '')
        {

        }
    }
    render()
    {
        return (
                <div>
                <Divider orientation="left" >
                    <Space>
                        <Button
                                icon={<SearchOutlined />}
                                className="search-order"
                        >搜索</Button>
                    </Space>
                </Divider>
                <Table
                        loading={this.state.listLoading}
                        dataSource={this.state.dataSource}
                        pagination={false}
                        columns={this.state.columns} />
                <Pagination
                    className="manage"
                    onChange={this.pageChange}
                    total={this.state.listCount}
                    pageSize={this.state.limit}
                    current={this.state.page}
                    showSizeChanger={true}
                    pageSizeOptions={['5','10','20']}
                     />
        </div>
        );
    }
}