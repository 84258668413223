import React from 'react';

import {Button, Divider, Form, Input,Popconfirm,Modal, message,Space,Table,Pagination} from 'antd';
import {Post} from '../../../Http';
import './index.css';
import { SearchOutlined } from '@ant-design/icons';
const { TextArea } = Input;
export default class index extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            /*取消订单*/
            'cancelOrderStatus':false,
            'cancelOrderId':'',
            'cancelOrderLoading':false,

            /*订单类型*/
            'orderType':0,

            /*列表加载中*/
            'listLoading':false,
            /*数据总数*/
            'page':1,
            'limit':5,
            'listCount':0,
            'dataSource':[],
            'columns':[
                { title: '订单号', dataIndex: 'ordernumber', key: 'ordernumber',width:100},
                { title: '支付单号',dataIndex: 'opayorders',key: 'opayorders',width:150},
                { title: '申请原因',dataIndex: 'orefund',key: 'orefund',width:210 },
                { title: '姓名',dataIndex: 'oname',key: 'oname',width:100 },
                { title: '电话',dataIndex: 'ophone',key: 'ophone',width:100 },
                { title: '实际付款',dataIndex: 'omonery',key: 'omonery',width:100 },
                { title: '操作',dataIndex: 'ordernumber', key: 'operate',width:150,
                    render: (orderid,item) => (
                        <StatusButtom refs={[this,item]} />
                    ),
                }
            ]
        };
    }
    /*初始化*/
    componentDidMount()
    {
        this.pageChange();

    }

    pageChange = (page = 1,limit = 5) =>
    {
        let rhis = this;
        this.setState({
            'listLoading':true,
            'page':page,
            'limit':limit,
        },() => {
            Post('Order/afterSale',{
                'mode':'list',
                'page':rhis.state.page,
                'type':rhis.state.orderType,
                'limit':rhis.state.limit
            }).then(res => {
                if(res.code === 200)
                {
                    rhis.setState({
                        'page':page,
                        'limit':limit,
                        'listLoading':false,
                        'listCount':res.data.count,
                        'dataSource':res.data.list
                    });
                    return;
                }
                message.error(res.data);
            });
        });
    }
    /*搜索订单*/
    SearchOrder = (id) => {
        if(id !== '')
        {

        }
    }
    /*同意退款*/
    ConfirmOrder = (id) => {
        Post('Order/afterSale',{
            'id':id,
            'mode':'update',
            'type':5,
            'text':''
        }).then(res => {
            if(res.code === 200)
            {
                this.pageChange();
                message.success(res.data);
                return;
            }
            message.error(res.data);
        });
    }
    /*拒绝退款*/
    CancelOrder = (id) => {
        this.setState({
            'cancelOrderStatus':true,
            'cancelOrderId':id
        });
    }
    /*切换类型*/
    SwitchOrderType = (type) => {
        this.setState({'orderType':type,'page':1 },() => {
            this.pageChange();
        });
    }
    /*打开微信商户平台*/
    OpenWeChat = () => {
        window.open("https://pay.weixin.qq.com/index.php/core/home/login");
    }
    render()
    {
        return (
                <div>
                <Divider orientation="left" >
                    <Space>
                        <Button onClick={() => { this.SwitchOrderType(0) }} type={this.state.orderType === 0 ? "primary":''}>待处理</Button>
                        <Button onClick={() => { this.SwitchOrderType(1) }} type={this.state.orderType === 1 ? "primary":''}>已处理</Button>
                        <Button onClick={() => {this.SearchOrder('')}}
                                icon={<SearchOutlined />}
                                className="search-order"
                        >搜索订单</Button>
                        <Button
                        onClick={this.OpenWeChat}
                        >打开微信商户平台</Button>
                    </Space>
                </Divider>
                <Table
                        loading={this.state.listLoading}
                        dataSource={this.state.dataSource}
                        pagination={false}
                        columns={this.state.columns} />
                <Pagination
                    className="manage"
                    onChange={this.pageChange}
                    total={this.state.listCount}
                    pageSize={this.state.limit}
                    current={this.state.page}
                    showSizeChanger={true}
                    pageSizeOptions={['5','10','20']}
                     />


                    <CloseOrder refs={this} />
        </div>
        );
    }
}
/*拒绝退款*/
function CloseOrder(pro)
{
    let refs = pro.refs;
    const [form] = Form.useForm();

    const handleCancel = () => {
        refs.setState({
           'cancelOrderStatus':false
        });
    };
    const onFinish = (values: any) => {
        refs.setState({
            'cancelOrderLoading':true
        });
        Post('Order/afterSale',{
            'id':refs.state.cancelOrderId,
            'mode':'update',
            'type':6,
            'text':values.reason
        }).then(res => {
            if(res.code === 200)
            {
                refs.pageChange();
                refs.setState({
                    'cancelOrderStatus':false,
                    'cancelOrderLoading':false
                });
                message.success(res.data);
                return;
            }
            message.error(res.data);
        });
    };
    return (
        <Modal title={'拒绝【'+ refs.state.cancelOrderId + '】订单提示'} width={400}
                destroyOnClose={true} footer={null}
                visible={refs.state.cancelOrderStatus} onCancel={handleCancel} >
    <Form
    form={form}
    layout='horizontal'
    name="chuku"
    labelCol={{ span: 0 }}
    wrapperCol={{ span: 25 }}
    onFinish={onFinish}
    autoComplete="off" >
                <Form.Item
                className="ant-form-item-buttom"
                key={'kk'}
                name="reason"
                rules={[{ required: true, message: '请填写拒绝退款原因' }]}>
                    <TextArea  rows={5} />
                </Form.Item>

            <Form.Item className="ant-form-item-buttom" wrapperCol={{ offset:0, span: 0 }}>
            <Button type="primary"
                className="close-order-bottom"
                loading={refs.state.cancelOrderLoading}
                htmlType="submit">确认</Button>
            </Form.Item>
        </Form>
        </Modal>
    );
}
/*操作按钮*/
function StatusButtom(pro)
{
    let refs = pro.refs;
    let buttom = '';
    if(refs[1].ostatus === 4)
    {
        buttom = (
            <Space>
            <Popconfirm
                title="确认已退款吗？"
                okText="确认"
                cancelText="取消"
                onConfirm={() => {refs[0].ConfirmOrder(refs[1].ordernumber) }}>
                <Button type="primary" shape="round" size="small">同意</Button>
            </Popconfirm>
            <Button onClick={() => {refs[0].CancelOrder(refs[1].ordernumber) }} type="primary" shape="round" size="small" danger>拒绝</Button>
        </Space>);
    }else{
        buttom =  (<Space> </Space>);
    }

    return buttom;
}