import React from 'react';

import {Button, Divider,Input, message,Space,Table,Pagination} from 'antd';
import {Post} from '../../../Http';
import './index.css';
import { SearchOutlined } from '@ant-design/icons';
export default class index extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            /*搜索单号*/
            'searchId':'',
            /*列表加载中*/
            'listLoading':false,
            /*数据总数*/
            'page':1,
            'limit':5,
            'listCount':0,
            'dataSource':[],
            'columns':[
                { title: '订单号', dataIndex: 'agent', key: 'agent',width:100},
                { title: '第三方单号',dataIndex: 'jnet',key: 'jnet',width:150},
                { title: '付款金额',dataIndex: 'payam',key: 'payam',width:110 },
                { title: '支付时间',dataIndex: 'time',key: 'time',width:150 },
                { title: '用户ID',dataIndex: 'userid',key: 'userid',width:100 },
                { title: '支付类型',dataIndex: 'rtype',key: 'rtype',width:100 }
            ]
        };
    }
    /*初始化*/
    componentDidMount()
    {
        this.pageChange();

    }

    pageChange = (page = 1,limit = 5,id= '') =>
    {
        let rhis = this;
        this.setState({
            'listLoading':true,
            'page':page,
            'limit':limit,
        },() => {
            Post('Order/tradeLog',{
                'mode':'list',
                'page':rhis.state.page,
                'id':id,
                'limit':rhis.state.limit
            }).then(res => {
                if(res.code === 200)
                {
                    rhis.setState({
                        'page':page,
                        'limit':limit,
                        'listLoading':false,
                        'listCount':res.data.count,
                        'dataSource':res.data.list
                    });
                    return;
                }
                message.error(res.data);
            });
        });
    }
    /*搜索订单*/
    SearchOrder = () => {
        if(this.state.searchId !== '')
        {
            this.pageChange(1,this.state.limit,this.state.searchId);
        }else{
            this.pageChange(1,this.state.limit);
        }
    }
    onChangeInput = (e) => {
        this.setState({
           'searchId':e.target.value
        });
    }
    render()
    {
        return (
                <div>
                <Divider orientation="left" >
                    <Space>
                        <Input name='id' onChange={ this.onChangeInput.bind(this) } maxLength="30" className="search-input" placeholder="输入本平台订单号" />
                         <Button onClick={this.SearchOrder} icon={<SearchOutlined />} >搜索订单</Button>
                    </Space>
                </Divider>
                <Table
                        loading={this.state.listLoading}
                        dataSource={this.state.dataSource}
                        pagination={false}
                        columns={this.state.columns} />
                <Pagination
                    className="manage"
                    onChange={this.pageChange}
                    total={this.state.listCount}
                    pageSize={this.state.limit}
                    current={this.state.page}
                    showSizeChanger={true}
                    pageSizeOptions={['5','10','20']}
                     />
        </div>
        );
    }
}