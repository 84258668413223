import axios from 'axios';
import Qs from 'qs';
import {message,notification} from 'antd';
	function getConfig(key = 'all',type = 1)
	{
		/*默认为本地调试模式*/
		let urls = ['http://127.0.0.5/admin.php/','https://xcx.chapad.com/api.php/'];
		let userinfo = sessionStorage.getItem('UserInfo');
		let AuthToken = ''; 
		if(userinfo)
		{
			userinfo = JSON.parse(userinfo);
			AuthToken = userinfo.AuthToken;
		}
		let  configs = {
				'uplodeImg':urls[type]+'Common/comUplodeImage',
				'headers':{
					'Auth-Token':AuthToken
				}
		};
		return key === 'all' ? configs:configs[key];
	}
	function Post(url,data = {},AuthToken= '',domain = 1)
	{
		let urls = ['http://127.0.0.5/admin.php/','https://xcx.chapad.com/api.php/'];
		let dom = urls[domain];
		let userinfo = sessionStorage.getItem('UserInfo');
		if(userinfo)
		{
			userinfo = JSON.parse(userinfo);
			AuthToken = userinfo.AuthToken;
		}
		return new Promise((Callback) => {
			axios({
				'method':'post',
				'url': url,
				'baseURL':dom,
				'headers': {
					'Content-Type':'application/x-www-form-urlencoded',
					'Auth-Token':AuthToken
				},
				'timeout': 20000,
				'responseType': 'json',
				'xsrfCookieName': 'XSRF-TOKEN',
				'xsrfHeaderName': 'X-XSRF-TOKEN',  
				'data': Qs.stringify(data)
			})
		  .then(function (response:any) {
			  if(response.status === 200)
			  {
			  	if(response.data.code !== 200 && response.data.code !== 500)
			  	{
					message.error(response.data.data);
					switch(response.data.code)
					{
						case 403:
							sessionStorage.removeItem('UserInfo');
							window.location.reload();
							break;
						default:
					}
					return;
			  	}
			  	return Callback(response.data);
			  }else{
				  notification.open({message: '请求失败', description:'服务器响应错误！'});
			  }
		  }).catch(function () {
				notification.open({message: '请求失败', description:'请检查网络！'});
		  });
		});
	}
export {Post,getConfig}
