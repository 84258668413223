import React from 'react';

import {Button, Form, Input,Radio,Select,Upload, message,Space} from 'antd';
import { CloseCircleOutlined, PlusOutlined, MinusCircleOutlined,LeftOutlined } from '@ant-design/icons';
import {Post,getConfig} from '../../../Http';
import './index.css';
const { Option } = Select;
export default class index extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            /*是否显示*/
            displayList:[
                { label: '显示', value: 1 },
                { label: '不显示', value: 0 }
            ],
            display:1,
            /*是否特价*/
            specialOfferList:[
                { label: '是', value: 1 },
                { label: '否', value: 0 }
            ],
            specialOffer:0,
            /*是否限购*/
            purchaseLimitList:[
                { label: '限购', value: 1 },
                { label: '不限购', value: 0 }
            ],
            /*封面图片*/
            cover:[],
            /*轮播图片*/
            carousel:[],
            /*分类列表*/
            categoryList:[],
            categoryListChild:[],
            categoryListChildValue:[],
            /*最终选择的栏目ID*/
            columnId:0,
            /*品类*/
            category:[],
            /*商品ID*/
            GoodsId:'',
            /*是否已请求过数据了*/
            isRequestData:false
        };

    }
    /*初始化*/
    componentDidMount()
    {
        if(typeof this.props.location.state === "undefined")
        {
            this.backtrack();
            return;
        }
        let config = document.createElement("script");
        config.src = "expand/ueditor/ueditor.config.js";
        config.async = false;
        document.getElementById('container').appendChild(config);

        let ueditor = document.createElement("script");
        ueditor.src = "expand/ueditor/ueditor.all.js";
        ueditor.async = false;
        document.getElementById('container').appendChild(ueditor);

        let lang = document.createElement("script");
        lang.src = "expand/ueditor/lang/zh-cn/zh-cn.js";
        lang.async = false;
        document.getElementById('container').appendChild(lang);
        let IntervalId = setInterval(function()
        {
            if(window.UE !== undefined && window.UE)
            {
                window.clearInterval(IntervalId);
                window.UE.getEditor('container');
            }
        },100);
        this.CategoriesList();
        this.setState({
            'GoodsId':this.props.location.state.id
        });
    }
    /*初始化表单数据*/
    loadData(form)
    {
        let rhis = this;
        if(this.state.GoodsId === '' || this.state.isRequestData === true){
            return;
        }
        Post('Commodity/goods',{'mode':'select','id':this.state.GoodsId}).then((res)=>{

            if(res.code === 200)
            {
                form.setFieldsValue({
                    'cname':res.data.cname,
                    'cdisplay':res.data.cdisplay,
                    'cspecialoffer':res.data.cspecialoffer
                });
                let ue = window.UE.getEditor('container');
                ue.ready(function(){
                    ue.setContent(res.data.ctext);
                });

                    rhis.setState({
                        'isRequestData':true,
                        'category':res.data.category,
                        'specialOffer':res.data.cspecialoffer,
                        'display':res.data.cdisplay,
                        'cover':[res.data.cimg],
                        'carousel':res.data.cimgcar,
                        'categoryListChild':res.data.categoryListChild,
                        'categoryListChildValue':res.data.categoryListChildValue,
                        'columnId':res.data.cpid
                    });

            }else{
                message.error(res.data);
            }
        });
    }
    /*获取商品分类*/
    CategoriesList()
    {
        let rhis = this;
        Post('Common/categoriesAll',{'mode':'list'}).then((res)=>{
            rhis.setState({
                'categoryList':res.data
            })
        })
    }
    /*卸载*/
    componentWillUnmount()
    {
        if(window.UE !== undefined)
        {
            window.UE.delEditor('container');
        }
        if(document.getElementById('edui_fixedlayer'))
        {
            document.getElementById('edui_fixedlayer').remove();
        }

    }
    /*按钮切换公共状态*/
    buttonToggle = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }
    /*分类*/
    classButtonToggle = (key,res) => {
        let category = this.state.category;
            category[key].slimited = Number(res.target.value);
        this.setState({
            'category': category
        });
    }

    /*删除图片*/
    deleteImg(key,type)
    {
        if(!type){ return;}
        let newData = this.state[type];
        newData.splice(key,1);
        this.setState({
            [type]:newData
        });
    }
    /*上传封面回调*/
    CoverChange(e)
    {
        if(e.file.response !== undefined)
        {
            if(e.file.response.msg)
            {
                let cover = this.state.cover;
                cover[0] = e.file.response.data;
                this.setState({
                    'cover':cover
                });
            }else{
               message.error(e.file.response.data);
            }
        }
    }
    /*上传轮播图回调*/
    carouselChange(e)
    {
        if(e.file.response !== undefined)
        {
            if(e.file.response.msg)
            {
                let carousel = this.state.carousel;
                carousel.push(e.file.response.data);
                this.setState({
                    'carousel':carousel
                });
            }else{
                message.error(e.file.response.data);
            }
        }
    }
    /*选择分类回调*/
    SelectToggle(type,attributes = [])
    {
        let categoryListChild = attributes.index === 0 ? []:this.state.categoryListChild;
          categoryListChild.forEach((v,k) => {
                if(k >= attributes.index)
                {
                    categoryListChild.splice(k,1);
                }
            });
        let categoryListChildValue = this.state.categoryListChildValue;
        categoryListChildValue.forEach((v,k) => {
            if(k >= attributes.index)
            {
                categoryListChildValue.splice(k,1);
            }
        });
              categoryListChildValue[attributes.index] = attributes.key;
            this.setState({
                'categoryListChild':categoryListChild,
                'categoryListChildValue':categoryListChildValue
            },() => {
                if(attributes.child.length)
                {
                    categoryListChild[attributes.index] = attributes.child;
                }
                this.setState({
                    'categoryListChild':categoryListChild,
                    'columnId':attributes.fid
                });
                
            });

    }
    /*品类*/
    categoryChange(key,name,refs,change)
    {
        let category = refs.state.category;
            category[key][name] = change.target.value;
        refs.setState({
            'category': category
        });
    }
    /*移除分类*/
    categoryRemove(key)
    {
        let category = this.state.category;
        delete category[key];
        this.setState({
           'category':category
        });
    }
    /*添加分类*/
    categoryAdd()
    {
        let category = this.state.category;
        category.push({
            sname:'',
            sbarcode:'',
            slimited:0,
            slimited_number:'',
            swholesale:0,
            scommission:0.1,
            sdisprice:'',
            sprice:'',
            snumber:99,
            spurchase:''
        });

        this.setState({
            'category':category
        });
    }

    /*调试验证失败*/
    submitDataFinish(form,res)
    {
       // console.log(res.values);
    }
    /*验证成功*/
    submitData(form,res)
    {
        let ue = window.UE.getEditor('container');
        let rhis = this;
        ue.ready(function(){
            let postData = {
                id:rhis.state.GoodsId,
                carousel:rhis.state.carousel,
                category:rhis.state.category,
                cdisplay:res.cdisplay,
                cname:res.cname,
                cover:rhis.state.cover,
                cpid:rhis.state.columnId,
                cspecialoffer:res.cspecialoffer,
                ctext:ue.getContent(),
                mode:'update'
            };
            Post('Commodity/goods',postData).then((res)=>{
               if(res.code === 200)
               {
                   message.success(res.data);
                   setTimeout(() => {
                       rhis.backtrack();
                   },2000);
                   return;
               }
                message.error(res.data);
            });
        });
    }
    /*返回*/
    backtrack()
    {
        window.history.go(-1);
    }
    render() {

        return (
            <Space>
            <FormData refs={this} />
            </Space>
        );
    }
}
/*表单内容*/
function FormData(pro)
{
    const [form] = Form.useForm();
    let rhis = pro.refs;
    rhis.loadData(form);
    return (<Form
    form={form}
    labelCol={{ span: 2 }}
    wrapperCol={{ span: 21 }}
    onFinish={rhis.submitData.bind(rhis,form)}
    onFinishFailed={rhis.submitDataFinish.bind(rhis,form)}
    autoComplete="off"
        >
        <Form.Item>
        <Button type="primary"  onClick={ rhis.backtrack } icon={<LeftOutlined />}>
        返回
        </Button>
        </Form.Item>

        <Form.Item
            label="品名"
            name="cname"
            rules={[{ required: true, message: '不能为空' }]}
        >
        <Input />
        </Form.Item>

        <Form.Item
    label="品类"
    style={{'maxHeight':'300px','overflowY':'scroll'}}
        >
        <CategoryList refs={rhis} name='category' />
        <Form.Item>
        <Button type="dashed" onClick={() => rhis.categoryAdd()} icon={<PlusOutlined />}>
        添加
        </Button>
        </Form.Item>

    </Form.Item>
    <Form.Item
    label="图文详情"
        >
        <div id="container" style={{'maxHeight':'320px','overflowY': 'scroll'}}></div>
</Form.Item>

<Form.Item
    label="封面图片"
        >
        <Upload
    name="file"
    headers={getConfig('headers')}
    data={{'mode':'insert','sizeX':200,'sizeY':250}}
    maxCount={10}
    multiple={true}
    listType="picture-card"
    className="avatar-uploader"
    showUploadList={false}
    accept="image/*"
    action={getConfig('uplodeImg')}
    onChange={rhis.CoverChange.bind(rhis)}
        >
        <div style={{ marginTop: 8 }}>上传</div>
</Upload>
<ImageLoad  refs={rhis} type="cover" />

    </Form.Item>
    <Form.Item
    label="轮播图"
        >
        <Upload
    name="file"
    headers={getConfig('headers')}
    data={{'mode':'insert','sizeX':500,'sizeY':400}}
    maxCount={50}
    multiple={true}
    listType="picture-card"
    className="avatar-uploader"
    showUploadList={false}
    accept="image/*"
    action={getConfig('uplodeImg')}
    onChange={rhis.carouselChange.bind(rhis)}
        >
        <div style={{ marginTop: 8 }}>上传</div>
</Upload>
<ImageLoad  refs={rhis} type="carousel" />

    </Form.Item>
    <Form.Item
    label="类目"
        >
        <Select  value={rhis.state.categoryListChildValue[0]}  style={{ width: 200 }} onChange={ rhis.SelectToggle.bind(rhis)}>
    {rhis.state.categoryList.map((res,key) => (
            <Option fid={res.fid} index={0} child={res.child}  key={key}>{res.fname}</Option>
))}
</Select>
<ColumnComponent refs={rhis} />
</Form.Item>

<Form.Item
    label="特价"
    name="cspecialoffer"
    initialValue={rhis.state.specialOffer}
        >
        <Radio.Group
    buttonStyle="solid"
    options={rhis.state.specialOfferList}
    onChange={ rhis.buttonToggle}
    value={rhis.state.specialOffer}
    name="specialOffer"
    optionType="button"
        />
        </Form.Item>
        <Form.Item
    label="状态"
    name="cdisplay"
    initialValue={rhis.state.display}
        >
        <Radio.Group
    buttonStyle="solid"
    options={rhis.state.displayList}
    onChange={ rhis.buttonToggle}
    value={rhis.state.display}
    name="display"
    optionType="button"
        />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 2, span: 12 }}>
<Button type="primary" htmlType="submit" style={{'width':'200px'}}>
    保存修改
    </Button>
    </Form.Item>
    </Form>);

}
/*
品类组件
*/
function CategoryList(pro)
{
    let dom = pro.refs.state.category.map((v,k) => {
    return (<Space key={k}  style={{ display: 'flex' }} align="baseline">
    <Form.Item
        name={'sname['+k+']'}
        initialValue={v.sname}
        onChange={pro.refs.categoryChange.bind(this,k,'sname',pro.refs)}
        rules={[{ required: true, message: '不能为空' }]}
        >
        <Input size="small" placeholder="名称" style={{'width':'200px'}} />
    </Form.Item>
    <Form.Item
        name={'sbarcode['+k+']'}
        onChange={pro.refs.categoryChange.bind(this,k,'sbarcode',pro.refs)}
        initialValue={v.sbarcode}
        rules={[{ required: true, message: '不能为空' }]}
    >
    <Input size="small" placeholder="条码" style={{'width':'130px'}} />
    </Form.Item>
    <Form.Item
        name={'spurchase['+k+']'}
        onChange={pro.refs.categoryChange.bind(this,k,'spurchase',pro.refs)}
        initialValue={v.spurchase}
        rules={[{ required: true, message: '不能为空' }]}
    >
        <Input size="small" placeholder="进价" />
    </Form.Item>

    <Form.Item
        onChange={pro.refs.categoryChange.bind(this,k,'sprice',pro.refs)}
        name={'sprice['+k+']'}
        initialValue={v.sprice}
    rules={[{ required: true, message: '不能为空' }]}
        >
        <Input size="small" placeholder="价格" />
    </Form.Item>
    <Form.Item
        name={'sdisprice['+k+']'}
        onChange={pro.refs.categoryChange.bind(this,k,'sdisprice',pro.refs)}
        initialValue={v.sdisprice}
    rules={[{ required: true, message: '不能为空' }]}
        >
        <Input size="small" placeholder="折扣价" />
    </Form.Item>
            <Form.Item
        name={'snumber['+k+']'}
        onChange={pro.refs.categoryChange.bind(this,k,'snumber',pro.refs)}
        initialValue={v.snumber}
        rules={[{ required: true, message: '不能为空' }]}
            >
            <Input size="small" placeholder="库存量" />
            </Form.Item>
    <Form.Item
        name={'scommission['+k+']'}
        onChange={pro.refs.categoryChange.bind(this,k,'scommission',pro.refs)}
        initialValue={v.scommission}
    rules={[{ required: true, message: '不能为空' }]}
        >
        <Input size="small" placeholder="佣金" />
    </Form.Item>
    <Form.Item
        name={'swholesale['+k+']'}
        onChange={pro.refs.categoryChange.bind(this,k,'swholesale',pro.refs)}
        initialValue={v.swholesale}
        rules={[{ required: true, message: '不能为空' }]}
        >
        <Input size="small" placeholder="配送费" />
    </Form.Item>

    <Form.Item
        name={'slimited['+k+']'}
        initialValue={v.slimited}
        rules={[{ required: true, message: '不能为空' }]}
        >
        <Radio.Group
            size="small"
            buttonStyle="solid"
            style={{'width':'110px'}}
            options={pro.refs.state.purchaseLimitList}
            onChange={ pro.refs.classButtonToggle.bind(this,k)}
            value={v.slimited}
            optionType="button"
        />
        </Form.Item>
        <Form.Item
        name={'slimited_number['+k+']'}
        onChange={pro.refs.categoryChange.bind(this,k,'slimited_number',pro.refs)}
        initialValue={v.slimited_number}
            hidden={v.slimited === 0}

        >
        <Input size="small" style={{'width':'80px'}} placeholder="限购数量" />
        </Form.Item>
        <MinusCircleOutlined onClick={() => pro.refs.categoryRemove(k) } />
    </Space>);
    });
    return (<div>{dom}</div>);
}
/*分类组件*/
function ColumnComponent(pro)
{
    let dom = pro.refs.state.categoryListChild.map((res,k) => {
         return (<Select  defaultValue="请选择分类" value={pro.refs.state.categoryListChildValue[(k+1)]} key={k} style={{ width: 200 }} onChange={ pro.refs.SelectToggle.bind(pro.refs)}>
                    {res.map((res,key) => (
                        <Option fid={res.fid} index={k+1} child={res.child} key={key}>{res.fname}</Option>
                    ))}
                </Select>
            );
    });
    return (<span>{dom}</span>);
}
function ImageLoad(pro)
{
    let data = pro.refs.state[pro.type];
    if(data.length)
    {
        let list = data.map((item,index) => {
            return  (
                <div key={index} className="product-picture">
                    <img width={100} alt=""  src={item} />
                    <CloseCircleOutlined onClick={() => { pro.refs.deleteImg(index,pro.type)  }} className="product-picture-a" />
                </div>
            );
        });
        return (<span>{list}</span>);
    }
    return (<span></span>);

}
