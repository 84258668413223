import React from 'react';

import {Link } from "react-router-dom";
import {Button, Divider, Input, message,Space,Table,Pagination,Tag,Popconfirm} from 'antd';
import {Post} from '../../../Http';
import './index.css';
import { SearchOutlined } from '@ant-design/icons';
export default class index extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            /*搜索商品*/
            'searchId':'',
            /*列表加载中*/
            'listLoading':false,
            /*数据总数*/
            'page':1,
            'limit':5,
            'listCount':0,
            'dataSource':[],
            'columns':[
                { title: 'ID', dataIndex: 'cid', key: 'cid',width:40},
                { title: '所属分类',dataIndex: 'cpid',key: 'cpid',width:100,
                    render:(cpid) => (
                        <Tag color="#2db7f5">{cpid}</Tag>
                    )
                },
                { title: '商品名称',dataIndex: 'cname',key: 'cname',width:220 },
                { title: '状态',dataIndex: 'cdisplay',key: 'cdisplay',width:100,
                    render: (cdisplay) => (
                            <GoodsStatus status={cdisplay} />
                        )
                },
                { title: '是否特价',dataIndex: 'cspecialoffer',key: 'cspecialoffer',width:100,
                    render:(cspecialoffer) => (
                        <GoodsSpecialOffer status={cspecialoffer} />
                    )
                },
                { title: '操作',dataIndex: 'cid',key: 'cid',width:150,
                    render:(cid) => (
                    <GoodsOperate id={cid} refs={this} />
                ) }
            ]
        };
    }
    /*初始化*/
    componentDidMount()
    {
        this.pageChange();
    }
    pageChange = (page = 1,limit = 5,id= '') =>
    {
        let rhis = this;
        this.setState({
            'listLoading':true,
            'page':page,
            'limit':limit,
        },() => {
            Post('Commodity/goods',{
                'mode':'list',
                'page':rhis.state.page,
                'id':id,
                'limit':rhis.state.limit
            }).then(res => {
                if(res.code === 200)
                {
                    rhis.setState({
                        'page':page,
                        'limit':limit,
                        'listLoading':false,
                        'listCount':res.data.count,
                        'dataSource':res.data.list
                    });
                    return;
                }
                message.error(res.data);
            });
        });
    }
    /*搜索商品*/
    SearchOrder = () => {
        if(this.state.searchId !== '')
        {
            this.pageChange(1,this.state.limit,this.state.searchId);
        }else{
            this.pageChange(1,this.state.limit);
        }
    }
    onChangeInput = (e) => {
        this.setState({
           'searchId':e.target.value
        });
    }
    render()
    {
        return (
                <div>
                <Divider orientation="left" >
                    <Space>
                        <Input name='id' onChange={ this.onChangeInput.bind(this) } maxLength="30" className="search-input" placeholder="输入名称或商品ID" />
                         <Button onClick={this.SearchOrder} icon={<SearchOutlined />} >搜索商品</Button>
                    </Space>
                </Divider>
                <Table
                        loading={this.state.listLoading}
                        dataSource={this.state.dataSource}
                        pagination={false}
                        columns={this.state.columns} />
                <Pagination
                    className="manage"
                    onChange={this.pageChange}
                    total={this.state.listCount}
                    pageSize={this.state.limit}
                    current={this.state.page}
                    showSizeChanger={true}
                    pageSizeOptions={['5','10','20']}
                     />
        </div>
        );
    }
}
/*操作按钮*/
function GoodsOperate(pro)
{
    const select = () => {

    }
    const deleteData = () => {
        let rhis = pro.refs;
        Post('Commodity/goods',{
            'mode':'delete',
            'id':pro.id
        }).then(res => {
            if(res.code === 200)
            {
                message.success(res.data);
                setTimeout(()=>{
                    rhis.pageChange();
                },2000);
                return;
            }
            message.error(res.data);
        });
    }
    return (<Space>

            <Link to={{pathname:'/commodity/goodsRevise',state:{'id':pro.id}}} className="ant-btn ant-btn-primary ant-btn-sm">修改</Link>
            <Button onClick={select} size="small" type="primary">查看品类</Button>
            <Popconfirm title="确定要删除吗？" onConfirm={deleteData} okText="确定" cancelText="取消">
                <Button size="small" type="primary" danger>删除</Button>
            </Popconfirm>


    </Space>);
}
/*显示状态*/
function GoodsStatus(pro)
{
    if(pro.status === 1)
    {
        return (<Tag color="#87d068">显示中</Tag> );
    }
    return ( <Tag color="#f50">未显示</Tag>);
}
/*是否特价*/
function GoodsSpecialOffer(pro)
{
    if(pro.status === 1)
    {
        return (<Tag color="#f50">是</Tag>);
    }
    return ( <Tag color="#87d068">否</Tag>);
}