import React from 'react';

import {Button, Divider, Form, Input,Modal, message,Space,Table,Pagination} from 'antd';
import {Post} from '../../../Http';
import './index.css';
import { SearchOutlined } from '@ant-design/icons';
const { TextArea } = Input;
export default class index extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            /*驳回审核*/
            'cancelOrderStatus':false,
            'cancelOrderId':'',
            'cancelOrderLoading':false,
            'cancelOrderType':'',

            /*显示类型*/
            'orderType':0,

            /*列表加载中*/
            'listLoading':false,
            /*数据总数*/
            'page':1,
            'limit':5,
            'listCount':0,
            'dataSource':[],
            'columns':[
                { title: '序列ID', dataIndex: 'cid', key: 'cid',width:100},
                { title: '用户ID',dataIndex: 'cuid',key: 'cuid',width:110},
                { title: '内容',dataIndex: 'centen',key: 'centen',width:210 },
                { title: '时间',dataIndex: 'time',key: 'time',width:150 },
                { title: '类型',dataIndex: 'type',key: 'type',width:100,
                    render: (id,item) => (
                        <Space>
                        <Button size="small" type="primary" danger={item.type === 1 ? true:false} > {item.type === 1 ? '投诉':'建议'}</Button>

                        </Space>

                )},
                { title: '处理时间',dataIndex: 'uptime',key: 'uptime',width:150 },
                { title: '操作',dataIndex: 'cid', key: 'cid',width:100,
                    render: (id,item) => (
                        <StatusButtom refs={[this,item]} />
                    )
                }
            ]
        };
    }
    /*初始化*/
    componentDidMount()
    {
        this.pageChange();
    }

    pageChange = (page = 1,limit = 5) =>
    {
        let rhis = this;
        this.setState({
            'listLoading':true,
            'page':page,
            'limit':limit,
        },() => {
            Post('User/feedback',{
                'mode':'list',
                'page':rhis.state.page,
                'type':rhis.state.orderType,
                'limit':rhis.state.limit
            }).then(res => {
                if(res.code === 200)
                {
                    rhis.setState({
                        'page':page,
                        'limit':limit,
                        'listLoading':false,
                        'listCount':res.data.count,
                        'dataSource':res.data.list
                    });
                    return;
                }
                message.error(res.data);
            });
        });
    }
    /*搜索用户*/
    SearchOrder = (id) => {
        if(id !== '')
        {

        }
    }
    /*驳回审核*/
    CancelOrder = (id,type) => {
        this.setState({
            'cancelOrderStatus':true,
            'cancelOrderId':id,
            'cancelOrderType':type
        });
    }
    /*切换类型*/
    SwitchOrderType = (type) => {
        this.setState({'orderType':type,'page':1 },() => {
            this.pageChange();
        });
    }
    render()
    {
        return (
                <div>
                <Divider orientation="left" >
                    <Space>
                        <Button onClick={() => { this.SwitchOrderType(0) }} type={this.state.orderType === 0 ? "primary":''}>待处理</Button>
                        <Button onClick={() => { this.SwitchOrderType(1) }} type={this.state.orderType === 1 ? "primary":''}>已处理</Button>
                        <Button onClick={() => {this.SearchOrder('')}}
                                icon={<SearchOutlined />}
                                className="search-order"
                        >搜索</Button>
                    </Space>
                </Divider>
                <Table
                        loading={this.state.listLoading}
                        dataSource={this.state.dataSource}
                        pagination={false}
                        columns={this.state.columns} />
                <Pagination
                    className="manage"
                    onChange={this.pageChange}
                    total={this.state.listCount}
                    pageSize={this.state.limit}
                    current={this.state.page}
                    showSizeChanger={true}
                    pageSizeOptions={['5','10','20']}
                     />


                    <CloseOrder refs={this} />
        </div>
        );
    }
}
/*处理*/
function CloseOrder(pro)
{
    let refs = pro.refs;
    const [form] = Form.useForm();

    const handleCancel = () => {
        refs.setState({
           'cancelOrderStatus':false
        });
    };
    const onFinish = (values: any) => {
        refs.setState({
            'cancelOrderLoading':true
        });
        Post('User/feedback',{
            'id':refs.state.cancelOrderId,
            'mode':'update',
            'type':refs.state.cancelOrderType,
            'text':values.reason
        }).then(res => {
            if(res.code === 200)
            {
                refs.pageChange();
                refs.setState({
                    'cancelOrderStatus':false,
                    'cancelOrderLoading':false
                });
                message.success(res.data);
                return;
            }
            message.error(res.data);
        });
    };
    return (
        <Modal title={'回复序号【'+ refs.state.cancelOrderId + '】用户'} width={400}
                destroyOnClose={true}
                footer={null}
                visible={refs.state.cancelOrderStatus} onCancel={handleCancel} >
    <Form
    form={form}
    layout='horizontal'
    name="chuku"
    labelCol={{ span: 0 }}
    wrapperCol={{ span: 25 }}
    onFinish={onFinish}
    autoComplete="off" >
                <Form.Item
                className="ant-form-item-buttom"
                key={'kk'}
                name="reason"
                rules={[{ required: true, message: '请填写原因' }]}>
                    <TextArea  rows={5} />
                </Form.Item>

            <Form.Item className="ant-form-item-buttom" wrapperCol={{ offset:0, span: 0 }}>
            <Button type="primary"
                className="close-order-bottom"
                loading={refs.state.cancelOrderLoading}
                htmlType="submit">确认</Button>
            </Form.Item>
        </Form>
        </Modal>
    );
}
/*操作按钮*/
function StatusButtom(pro)
{
    let refs = pro.refs;
    let buttom = '';
    switch(refs[1].site) {
        case 0:
            buttom = (
                <Space>
                    <Button onClick={() => {refs[0].CancelOrder(refs[1].cid,1) }} type="primary" shape="round" size="small">处理</Button>
                </Space>);
            break;
        case 4:
            buttom =  (<Space> </Space>);
            break;
        default:

    }
    return buttom;
}