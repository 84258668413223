import React from 'react';
import {HashRouter as Router, Route,Redirect,Link,Switch } from "react-router-dom";
import {Post} from '../Http';
/*注册页面*/
import {
    Home,
    Login,
    OrderManage,
    OrderAfterSale,
    UserList,
    UserRider,
    UserFeedback,
    UserWithdraw,
    OrderTradeLog,
    OrderDepot,
    CommodityIncrease,
    CommodityGoodslist,
    CommodityGoodsRevise
} from './AllRoute';

import { Layout, Menu,message } from 'antd';

import './index.css';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

export default class index extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            /*全局是否登录*/
            'islogin':true,
            /*顶部菜单栏*/
            'navTop':[],
            /*左边菜单栏*/
            'navLeftAll':[],
            'navLeft':[]
        };
    }
    /*初始化*/
    componentDidMount()
    {
        let userinfo = sessionStorage.getItem('UserInfo');
        if(userinfo === null)
        {
            this.setState({
                'islogin':false
            });
        }else{
            this.communication('readLogin');
        }
    }
    /*
    *父子组件通讯
    * res 通讯类型
    * readLogin 更新登录状态
    */
    communication = (res) => {
        if(res === 'readLogin')
        {
            if(this.state.islogin)
            {
                this.topColumnData();
            }
        }

    }

    /*顶部栏目数据*/
     topColumnData = () => {
        let mnuItem = '';
        let rhis = this;
        Post('Common/allColumn',{'mode':'list'}).then(res => {
            if(res.code === 200)
            {
                mnuItem = res.data.top.map((item,key) => {
                    return <Menu.Item className={'iconfont ' + item.cicon } key={key} >{item.cname}</Menu.Item>
                });
                rhis.setState({
                    'navTop':mnuItem,
                    'navLeftAll':res.data.left
                });
                if(res.data.top.length)
                {
                    rhis.leftColumnData();
                }
            }else{
                message.error(res.data);
            }
        });
    }
    /*左边菜单栏数据*/
     leftColumnData = (key = 0) => {
        let mnuItem = '';
        let rhis = this;
        mnuItem = rhis.state.navLeftAll[key].map((item,key) => {
            return (
                <SubMenu key={'sub' + key } icon={<i  className={'iconfont ' + item.cicon } />} title={item.cname} >
                {
                     item.child.map( (items,keys) => {
                         return (
                             <Menu.Item icon={<i  className={'iconfont ' + items.cicon } />} key={'sub' + key +'-'+ keys} >
                                <Link to={'/'+items.curl} >{items.cname}</Link>
                             </Menu.Item>
                         );
                     })
                }
                </SubMenu>
            );
        });
        rhis.setState({
            'navLeft':mnuItem
        });
    }

    render()
    {
        if(!this.state.islogin)
        {
            return (
                <Router>
                    <Route exact path={'/login'}> <Login refs={this} /> </Route>
                    <Redirect to={'/login'} />
                </Router>
            );
        }
        return (
            <Layout>
                    <Header className="header">
                        <div className="logo" />
                        <TopColumn refs={this} />
                    </Header>
                <Layout>
                <Router>
                    <Sider width={200} className="site-layout-background">
                            <LeftColumn refs={this} />
                    </Sider>
                <Layout>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}
                    >
                        <Switch>
                            <Route exact path={'/order/manage'} component={OrderManage}></Route>
                            <Route exact path={'/order/afterSale'} component={OrderAfterSale}></Route>
                            <Route exact path={'/order/tradeLog'} component={OrderTradeLog}></Route>
                            <Route exact path={'/order/depot'} component={OrderDepot}></Route>

                            <Route exact path={'/user/rider'} component={UserRider}></Route>
                            <Route exact path={'/user/feedback'} component={UserFeedback}></Route>
                            <Route exact path={'/user/withdraw'} component={UserWithdraw}></Route>
                            <Route exact path={'/user/list'} component={UserList}></Route>

                            <Route exact path={'/commodity/increase'} component={CommodityIncrease}></Route>
                            <Route exact path={'/commodity/goodslist'}  component={CommodityGoodslist}></Route>
                            <Route exact path={'/commodity/goodsRevise'} component={CommodityGoodsRevise}></Route>

                            <Route exact path={'/'} component={Home}></Route>
                            <Redirect from="/login" to={'/'} />
                        </Switch>
                    </Content>
                </Layout>
        </Router>
            </Layout>
        </Layout>
        );
    }
}
/*顶部栏目*/
function TopColumn(pro)
{
    let refs = pro.refs;
    const switchLeft = (item) => {
        refs.leftColumnData(item.key);
    };
    const loginOut = () => {

        sessionStorage.removeItem('UserInfo');
        refs.setState({
            'islogin':false
        });
    }
    return (
        <Menu theme="dark" onClick={switchLeft} defaultSelectedKeys={['0']} mode="horizontal" >
        {refs.state.navTop}
        <Menu.Item onClick={loginOut} key={9999} >退出登录</Menu.Item>
        </Menu>
    );
}
/*左边栏目*/
function LeftColumn(pro)
{
    let refs = pro.refs;
    let lengthCount = refs.state.navLeft.length;
    const rootSubmenuKeys = [];
    for(let i = 0;i < lengthCount;i++)
    {
        rootSubmenuKeys.push('sub'+i);
    }

    const [openKeys, setOpenKeys] = React.useState([]);

    const OpenChange = keys => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    return ( <Menu
    theme="dark"
    mode="inline"
    openKeys={openKeys}
    onOpenChange={OpenChange}
    style={{ height: '100vh', borderRight: 0 }}
>
    { refs.state.navLeft } </Menu>  );
}
