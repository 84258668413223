import React from 'react';
import Echarts from 'echarts-for-react';
import {Row, Col, message} from 'antd';
import {Post} from '../../Http';

import './index.css';
export default class index extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            'InstanceUser':'',
            'InstanceRider':'',
            'InstanceWithdraw':'',
            'InstanceOrder':'',
            'user':{
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { type: 'cross' }
                },
                xAxis: {
                    data: []
                },
                yAxis:  [
                        {
                            type: 'value',
                            min: 0,
                            max: 10,
                            offset:-10,
                            position: 'left',
                            axisLabel: {
                                formatter: '{value} 人'
                            }
                        }
                    ],
                series: [
                    {
                        name: '人数',
                        yAxisIndex: 0,
                        data: [],
                        type: 'line',
                        stack: 'x',
                        smooth: true,
                        areaStyle: {}
                    }
                ]
            },
            'withdraw':{
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { type: 'cross' }
                },
                xAxis: {
                    data: []
                },
                yAxis:  [
                    {
                        type: 'value',
                        min: 0,
                        max: 10,
                        offset:-10,
                        position: 'left',
                        axisLabel: {
                            formatter: '{value} 次'
                        }
                    }
                ],
                series: [
                    {
                        name: '次数',
                        yAxisIndex: 0,
                        data: [],
                        type: 'line',
                        stack: 'x',
                        smooth: true,
                        areaStyle: {}
                    }
                ]
            },
            'rider':{
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { type: 'cross' }
                },
                xAxis: {
                    data: []
                },
                yAxis:  [
                    {
                        type: 'value',
                        min: 0,
                        max: 10,
                        offset:-10,
                        position: 'left',
                        axisLabel: {
                            formatter: '{value} 元'
                        }
                    }
                ],
                series: [
                    {
                        name: '元',
                        yAxisIndex: 0,
                        data: [],
                        type: 'line',
                        stack: 'x',
                        smooth: true,
                        areaStyle: {}
                    }
                ]
            },
            'order':{
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { type: 'cross' }
                },
                xAxis: {
                    data: []
                },
                yAxis:  [
                    {
                        type: 'value',
                        min: 0,
                        max: 10,
                        offset:-10,
                        position: 'left',
                        axisLabel: {
                            formatter: '{value} 单'
                        }
                    }
                ],
                series: [
                    {
                        name: '单',
                        yAxisIndex: 0,
                        data: [],
                        type: 'line',
                        stack: 'x',
                        smooth: true,
                        areaStyle: {}
                    }
                ]
            }
        };

    }
    /*初始化*/
    componentDidMount()
    {
        let rhis = this;
        Post('Common/allHome',{'mode':'list'}).then(res => {
            if(res.code === 200)
            {
                let user = rhis.state.user;
                user.series[0].data = res.data.user.list;
                user.xAxis.data = res.data.time;
                user.yAxis[0].max = res.data.user.count;

                let order = rhis.state.order;
                order.series[0].data = res.data.order.list;
                order.xAxis.data = res.data.time;
                order.yAxis[0].max = res.data.order.count;

                let withdraw = rhis.state.withdraw;
                withdraw.series[0].data = res.data.withdraw.list;
                withdraw.xAxis.data = res.data.time;
                withdraw.yAxis[0].max = res.data.withdraw.count;

                let rider = rhis.state.rider;
                rider.series[0].data = res.data.rider.list;
                rider.xAxis.data = res.data.time;
                rider.yAxis[0].max = res.data.rider.count;
                rhis.setState({
                    'user':user,
                    'rider':rider,
                    'withdraw':withdraw,
                    'order':order
                });
                return;
            }
            message.error(res.data);
        });
    }
    componentDidUpdate() {
        this.state.InstanceUser.getEchartsInstance().setOption(this.state.user);
        this.state.InstanceUser.getEchartsInstance().resize();

        this.state.InstanceRider.getEchartsInstance().setOption(this.state.rider);
        this.state.InstanceRider.getEchartsInstance().resize();

        this.state.InstanceWithdraw.getEchartsInstance().setOption(this.state.withdraw);
        this.state.InstanceWithdraw.getEchartsInstance().resize();

        this.state.InstanceOrder.getEchartsInstance().setOption(this.state.order);
        this.state.InstanceOrder.getEchartsInstance().resize();
    }
    /*状态机*/
    stateMachine = (e,key,rhis) => {
        if(this.state.InstanceRider === '' ||
            this.state.InstanceWithdraw === '' ||
            this.state.InstanceOrder === '' ||
            this.state.InstanceUser === ''
        )
        {
            rhis.setState({
                [key]:e
            });
        }

    }

    render()
    {
        return (
            <Row gutter={[0,10]} justify="space-around" >
                <Col className="gutter-row home-grille" span={12}>
                    <div className="home-grille-title">新用户增长</div>
                    <Echarts  ref={(e) => { this.stateMachine(e,'InstanceUser',this) }} option={this.state.user} />
                </Col>
                <Col className="gutter-row home-grille" span={12}>
                    <div className="home-grille-title">日均订单数</div>
                    <Echarts  ref={(e) => { this.stateMachine(e,'InstanceOrder',this) }} option={this.state.user} />
                </Col>
                <Col className="gutter-row home-grille" span={12}>
                    <div className="home-grille-title">日均提现次数</div>
                    <Echarts  ref={(e) => { this.stateMachine(e,'InstanceWithdraw',this) }} option={this.state.user} />
                </Col>
                <Col className="gutter-row home-grille" span={12}>
                    <div className="home-grille-title">日均佣金发放</div>
                    <Echarts  ref={(e) => { this.stateMachine(e,'InstanceRider',this) }} option={this.state.user} />
                </Col>

            </Row>
        );
    }
}
