import React from 'react';

import {Button, Divider, Form, Input,Popconfirm,Modal, message,Space,Table,Pagination,Drawer,Descriptions} from 'antd';
import {Post} from '../../../Http';
import './index.css';
import { SearchOutlined } from '@ant-design/icons';
const { TextArea } = Input;
export default class index extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            /*自动刷新*/
            'autoRefreshId':'',
            'autoRefreshTime':30,

            /*取消订单*/
            'cancelOrderStatus':false,
            'cancelOrderId':'',
            'cancelOrderLoading':false,

            /*出库确认*/
            'outboundStatus':false,
            'outboundId':'',
            'outboundData':[],

            /*快捷查看单条订单信息*/
            'findOrderId':'',
            /*订单类型*/
            'orderType':0,
            /*搜索订单*/
            'orderInfo':[],
            'orderInfoLoading':false,
            /*展开搜索*/
            'searchStatus':false,
            /*列表加载中*/
            'listLoading':false,
            /*数据总数*/
            'page':1,
            'limit':5,
            'listCount':0,
            'dataSource':[],
            'columns':[
                { title: '订单号', dataIndex: 'ordernumber', key: 'ordernumber',width:80},
                { title: '收货人',dataIndex: 'oname',key: 'oname',width:80},
                { title: '电话',dataIndex: 'ophone',key: 'ophone',width:70 },
                { title: '收货地址',dataIndex: 'oaddress',key: 'oaddress',width:250 },
                { title: '总件数',dataIndex: 'onumber',key: 'onumber',width:70 },
                { title: '实际付款',dataIndex: 'omonery',key: 'omonery',width:90 },
                { title: '操作',dataIndex: 'ordernumber', key: 'operate',width:150,
                    render: (orderid,item) => (
                        <StatusButtom refs={[this,item]} />
                    ),
                }
            ]
        };
    }
    /*初始化*/
    componentDidMount()
    {
        this.pageChange();

    }

    pageChange = (page = 1,limit = 5) =>
    {
        let rhis = this;
        this.setState({
            'listLoading':true,
            'page':page,
            'limit':limit,
        },() => {
            Post('Order/manage',{
                'mode':'list',
                'page':rhis.state.page,
                'type':rhis.state.orderType,
                'limit':rhis.state.limit
            }).then(res => {
                if(res.code === 200)
                {
                    rhis.setState({
                        'page':page,
                        'limit':limit,
                        'listLoading':false,
                        'listCount':res.data.count,
                        'dataSource':res.data.list
                    });
                    return;
                }
                message.error(res.data);
            });
        });
    }
    /*搜索订单*/
    SearchOrder = (id) => {
        if(id !== '')
        {
            this.setState({
                'findOrderId':id
            },() => {
                    this.setState({
                        'searchStatus':true
                    });

            });
        }else{
            this.setState({
                'searchStatus':true
            });
        }
    }
    /*确认接单*/
    ConfirmOrder = (id) => {
        Post('Order/manage',{
            'order_id':id,
            'mode':'update',
            'action':'confirm'
        }).then(res => {
            if(res.code === 200)
            {
                this.pageChange();
                message.success(res.data);
                return;
            }
            message.error(res.data);
        });
    }
    /*取消订单*/
    CancelOrder = (id) => {
        this.setState({
            'cancelOrderStatus':true,
            'cancelOrderId':id
        });
    }
    /*出库确认*/
    OutboundConfir = (id) => {
        let rhis = this;
        Post('Order/manage',{'mode':'select','action':'checklist','id':id}).then(res => {
            if(res.code === 200)
            {
                rhis.setState({
                    'outboundId':id,
                    'outboundData':res.data,
                    'outboundStatus':true
                });
                return;
            }
            message.error(res.data);
        });
    }
    /*切换订单类型*/
    SwitchOrderType = (type) => {
        this.setState({'orderType':type,'page':1 },() => {
            this.pageChange();
        });
    }
    /*自动刷新*/
    AutoRefresh = () => {
        if(this.state.autoRefreshId)
        {
            clearInterval(this.state.autoRefreshId);
            this.setState({
                'autoRefreshId':'',
                'autoRefreshTime':30
            });
            return;
        }
        let rhis = this;
        let id = setInterval(() => {
            if(rhis.state.autoRefreshTime === 0)
            {
                rhis.setState({
                    'autoRefreshTime':30
                });
                console.log('000');
                rhis.pageChange();
            }else{
                let autoRefreshTime = rhis.state.autoRefreshTime - 1;
                console.log(autoRefreshTime);
                rhis.setState({
                    'autoRefreshTime':autoRefreshTime
                });
            }
        },1000);

        rhis.setState({
            'autoRefreshId':id
        });
    }
    render()
    {
        return (
                <div>
                <Divider orientation="left" >
                    <Space>
                        <Button onClick={() => { this.SwitchOrderType(0) }} type={this.state.orderType === 0 ? "primary":''}>待处理</Button>
                        <Button onClick={() => { this.SwitchOrderType(1) }} type={this.state.orderType === 1 ? "primary":''}>待出库</Button>
                        <Button onClick={() => { this.SwitchOrderType(2) }} type={this.state.orderType === 2 ? "primary":''}>已出库</Button>
                        <Button onClick={() => { this.SwitchOrderType(3) }} type={this.state.orderType === 3 ? "primary":''}>已完成</Button>

                        <Button onClick={() => {this.SearchOrder('')}}
                                icon={<SearchOutlined />}
                                className="search-order"
                        >搜索订单</Button>
                        <Button onClick={this.AutoRefresh}>{this.state.autoRefreshId ? this.state.autoRefreshTime+'秒后':'开启自动'}刷新</Button>
                    </Space>
                </Divider>
                <Table
                        loading={this.state.listLoading}
                        dataSource={this.state.dataSource}
                        pagination={false}
                        columns={this.state.columns} />
                <Pagination
                    className="manage"
                    onChange={this.pageChange}
                    total={this.state.listCount}
                    pageSize={this.state.limit}
                    current={this.state.page}
                    showSizeChanger={true}
                    pageSizeOptions={['5','10','20']}
                     />
                     <SearchModule refs={this} />
                    <OutgoingModule refs={this} />
                    <CloseOrder refs={this} />
        </div>
        );
    }
}
/*关闭订单*/
function CloseOrder(pro)
{
    let refs = pro.refs;
    const [form] = Form.useForm();

    const handleCancel = () => {
        refs.setState({
           'cancelOrderStatus':false
        });
    };
    const onFinish = (values: any) => {
        refs.setState({
            'cancelOrderLoading':true
        });
        Post('Order/manage',{
            'order_id':refs.state.cancelOrderId,
            'mode':'update',
            'action':'close',
            'reason':values.reason
        }).then(res => {
            if(res.code === 200)
            {
                refs.pageChange();
                refs.setState({
                    'cancelOrderStatus':false,
                    'cancelOrderLoading':false
                });
                message.success(res.data);
                return;
            }
            message.error(res.data);
        });
    };
    return (
        <Modal title={'关闭【'+ refs.state.cancelOrderId + '】订单提示'} width={400}
                destroyOnClose={true} footer={null}
                visible={refs.state.cancelOrderStatus} onCancel={handleCancel} >
    <Form
    form={form}
    layout='horizontal'
    name="chuku"
    labelCol={{ span: 0 }}
    wrapperCol={{ span: 25 }}
    onFinish={onFinish}
    autoComplete="off" >
                <Form.Item
                className="ant-form-item-buttom"
                key={'kk'}
                name="reason"
                rules={[{ required: true, message: '请填写关闭订单原因' }]}>
                    <TextArea  rows={5} />
                </Form.Item>

            <Form.Item className="ant-form-item-buttom" wrapperCol={{ offset:0, span: 0 }}>
            <Button type="primary"
                className="close-order-bottom"
                loading={refs.state.cancelOrderLoading}
                htmlType="submit">确认关闭</Button>
            </Form.Item>
        </Form>
        </Modal>
    );
}
/*操作按钮*/
function StatusButtom(pro)
{
    let refs = pro.refs;
    let buttom = '';
    switch(refs[1].ostatus) {
        case 0:
            buttom = (
                <Space>
                   <Popconfirm
                    title="确认接单吗？"
                    okText="确认"
                    cancelText="取消"
                    onConfirm={() => {refs[0].ConfirmOrder(refs[1].ordernumber) }}>
                        <Button type="primary" shape="round" size="small">接单</Button>
                    </Popconfirm>
                   <Button onClick={() => {refs[0].CancelOrder(refs[1].ordernumber) }} type="primary" shape="round" size="small" danger>关闭</Button>
                    <Button onClick={() => {refs[0].SearchOrder(refs[1].ordernumber) }} type="primary" shape="round" size="small" >查看</Button>
                </Space>);
            break;
        case 1:
            buttom = (
                <Space>
                    <Button onClick={() => {refs[0].OutboundConfir(refs[1].ordernumber) }} type="primary" shape="round" size="small" >出库</Button>
                </Space>);
            break;
        default:
        buttom =  (<Space> </Space>);
    }
    return buttom;
}
/*出库清单确认*/
function OutgoingModule(pro)
{
    let refs = pro.refs;
    const [form] = Form.useForm();
    const onClose = () => {
        refs.setState({
            'outboundStatus':false,
            'outboundData':[]
        });
    };
    if(JSON.stringify(refs.state.outboundData) !== "[]")
    {
        let values = {};
        refs.state.outboundData.map((items) =>
        {
           return values[items.oshopid] = items.number;
        });
            form.setFieldsValue(values);
    }

    const onFinish = (values: any) => {
        refs.setState({
            'outboundLoading':true
        });
        Post('Order/manage',{'order_id':refs.state.outboundId,'mode':'insert','data':values}).then(res => {
            if(res.code === 200)
            {
                refs.setState({
                    'outboundLoading':false,
                    'outboundStatus':false
                });
                message.success(res.data);
                return;
            }
            message.error(res.data);
        });
    };
    return (
        <Drawer
    width={'60%'}
    title="出库清单"
    placement="right"
    onClose={onClose}
    visible={refs.state.outboundStatus}
        >
        <Form
    form={form}
    layout='inline'
    name="chuku"
    labelCol={{ span: 15 }}
    wrapperCol={{ span: 8 }}
    onFinish={onFinish}
    autoComplete="off"
        >
        { refs.state.outboundData.map((items,keysw) => {
                return (

                    <Space key={keysw} className="outbound-data-centent">
                    <span className="outbound-data">{ items.name} 【单价：{ items.monery } 元】</span>
                    <Form.Item
                    key={'k'+ items.oshopid}
                    label='实际出库数量：'
                    name={items.oshopid}
                    rules={[{ required: true, message: '' }]}
                        >
                        <Input  allowClear />
                    </Form.Item>
                </Space>
            )})}
            <Form.Item wrapperCol={{ offset:0, span: 15 }}>
                <Button type="primary"
                    className="outbound-data-bottom"
                    loading={refs.state.outboundLoading}
                    htmlType="submit">提交出库申请</Button>
                    </Form.Item>
        </Form>

</Drawer>
);
}
/*搜索订单*/
function SearchModule(pro)
{
    let refs = pro.refs;
    const [form] = Form.useForm();
    if(refs.state.findOrderId !== ''){
        form.setFieldsValue({'orderid':refs.state.findOrderId});
    }
    const onClose = () => {
        refs.setState({
            'searchStatus':false,
            'orderInfoLoading':false,
            'orderInfo':[]
        },() => {
            form.resetFields();
        });

    };
    const onFinish = (values: any) => {
        refs.setState({
            'orderInfoLoading':true
        });
        Post('Order/manage',{'mode':'select','id':values.orderid}).then(res => {
            if(res.code === 200)
            {
                refs.setState({
                    'orderInfo':res.data,
                    'orderInfoLoading':false
                });
                return;
            }
            message.error(res.data);
        });
    };
    return (
        <Drawer
            width={'60%'}
            title="搜索订单"
            placement="right"
            onClose={onClose}
            visible={refs.state.searchStatus}
        >
                <Form
                    form={form}
                    layout='inline'
                    name="info"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 28 }}
                    onFinish={onFinish}
                    autoComplete="off"
                        >
                        <Form.Item
                    label="订单号"
                    name="orderid"
                    rules={[{ required: true, message: '请输入订单号' }]}
                        >
                        <Input  allowClear />
                        </Form.Item>
                <Form.Item wrapperCol={{ offset:0, span: 8 }}>
                <Button type="primary"
                        icon={<SearchOutlined />}
                        loading={refs.state.orderInfoLoading}
                        htmlType="submit">搜索</Button>
                    </Form.Item>
                    </Form>
                    <Divider orientation="left" plain> 订单信息 </Divider>
                    { JSON.stringify(refs.state.orderInfo) === '[]' ? '' : (
                        < Descriptions
                        column={6}
                        bordered >
                        < Descriptions.Item label = "物品信息" span = {6} >< /Descriptions.Item>
                        { refs.state.orderInfo.oremar.map((items,keys) => {
                            return (
                                <Descriptions.Item
                                    labelStyle={{'width':'60%'}}
                                    key={keys}
                                    label={ items.name}
                                    span = {6} >
                                        <span className="order-info">数量：{ items.number } 件</span>
                                        <span>单价：{ items.monery } 元</span>
                            < /Descriptions.Item>
                        );

                        })}
                    </Descriptions>
                        )}
                    { JSON.stringify(refs.state.orderInfo) === '[]' ? '' : (
                            < Descriptions
                        column={6}
                        bordered >
                        < Descriptions.Item label = "详细信息" span = {6} >< /Descriptions.Item>
                        < Descriptions.Item
                        label = "订单号"
                        span = {3}
                            > {refs.state.orderInfo.ordernumber} < /Descriptions.Item>
                            < Descriptions.Item
                        label = "是否付款"
                        span = {2}
                            > {refs.state.orderInfo.opayment} < /Descriptions.Item>
                            < Descriptions.Item
                        label = "状态"
                        span = {1}
                            > {refs.state.orderInfo.ostatus} < /Descriptions.Item>
                            < Descriptions.Item
                        label = "支付单号"
                        span = {4} > {refs.state.orderInfo.opayorders} < /Descriptions.Item>
                            < Descriptions.Item
                        label = "下单时间"
                        span = {2}
                         > {refs.state.orderInfo.otime} < /Descriptions.Item>

                            < Descriptions.Item
                        label = "下单ID"
                        span = {2}
                         > {refs.state.orderInfo.ouserid} < /Descriptions.Item>
                            < Descriptions.Item
                        label = "总件数"
                        span = {2} > {refs.state.orderInfo.onumber} < /Descriptions.Item>
                            < Descriptions.Item
                        label = "实际付款"
                        span = {2} > {refs.state.orderInfo.omonery} < /Descriptions.Item>

                            < Descriptions.Item
                        label = "收货姓名"
                        span = {3} > {refs.state.orderInfo.oname} < /Descriptions.Item>
                            < Descriptions.Item
                        label = "收货电话"
                        span = {3} > {refs.state.orderInfo.ophone} < /Descriptions.Item>
                            < Descriptions.Item
                        span = {6}
                        label = "收货地址" > {refs.state.orderInfo.oaddress} < /Descriptions.Item>
                            < Descriptions.Item
                        label = "备注内容"
                        span = {6} > {refs.state.orderInfo.oremarks} < /Descriptions.Item>
                            < Descriptions.Item
                        label = "平台接单"
                        span = {3} > {refs.state.orderInfo.opickuptime} < /Descriptions.Item>
                            < Descriptions.Item
                        label = "骑手接单"
                        span = {3} > {refs.state.orderInfo.odistribution} < /Descriptions.Item>
                            < Descriptions.Item
                        label = "出库时间"
                        span = {3} > {refs.state.orderInfo.oshiptime} < /Descriptions.Item>

                            < Descriptions.Item
                        label = "骑手UID"
                        span = {3} > {refs.state.orderInfo.orideruid} < /Descriptions.Item>
                            < Descriptions.Item
                        label = "申请退原因"
                        span = {6} > {refs.state.orderInfo.orefund} < /Descriptions.Item>
                            < Descriptions.Item
                        label = "申请退时间"
                        span = {2} > {refs.state.orderInfo.orequestrefund} < /Descriptions.Item>
                            < Descriptions.Item
                        label = "额外配送费"
                        span = {2} > {refs.state.orderInfo.obasis} < /Descriptions.Item>
                            < Descriptions.Item
                        label = "总佣金"
                        span = {2} > {refs.state.orderInfo.omission} < /Descriptions.Item>
                            < Descriptions.Item
                        label = "同意退原因"
                        span = {6} > {refs.state.orderInfo.orefundtime} < /Descriptions.Item>
                            < Descriptions.Item
                        label = "拒绝退原因"
                        span = {6} > {refs.state.orderInfo.odenialreason} < /Descriptions.Item>

                            < Descriptions.Item
                        label = "骑手接单"
                        span = {3} > {refs.state.orderInfo.oqsjdtime} < /Descriptions.Item>
                            < Descriptions.Item
                        label = "完成时间"
                        span = {3} > {refs.state.orderInfo.ocompletetime} < /Descriptions.Item>

                            < /Descriptions> )
                    }


        </Drawer>
    );
}